body {
  margin: 0;
  background-image: url("../images/parallax.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  overflow-x:hidden;
}

.body-scroll{
  background-image: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Colors */
  --primary: #183DF9;
  --orange: #FF8E00;
  --orange-light:#FF970D;
  --dark: #313131;
  --light: #FFFFFF;
  --light-2:#F5F5F5;
  --gray:#0000005C;
  --gray-2:#00000054;
  --gray-3:#00000029
}
